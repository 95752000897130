import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Doughnut, Line, Pie,Bar } from 'react-chartjs-2';
import 'chart.js/auto';

function SalesAnalysis() {

    var currentDate = new Date();
    currentDate.setDate(1);
    var startDateOfMonth = currentDate.toISOString().split('T')[0];
  
    var todayDate = new Date().toISOString().split('T')[0];


    const [startDate,setStartDate] = useState(startDateOfMonth)
    const [endDate,setEndDate] = useState(todayDate)
    const [store,setStore] = useState('all')

    const [total,setTotal] = useState(0)
    const [totalGal,setTotalGal] = useState(0)
    const [totalWar,setTotalWar] = useState(0)

    
const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  
const [chartData2, setChartData2] = useState({
    labels: [],
    datasets: []
  });
  
  

    useEffect(() => {


        const data = {
            from_date : startDate,
            to_date : endDate,
            store : store
          }





        axios.post("https://bmserver.ccsoftwares.com/Sales/daywiseSales",data).then((res) =>{
            //console.log(res.data)
            // setStock(res.data)
            const salesData = res.data.data;
            const dates = salesData?.map(item => item.bill_date.split('T')[0]);
            const dailyTotal = salesData?.map(item => item.total_sales);
        
            setChartData({
              labels: dates,
              datasets: [
                {
                  label: 'Daily Sales',
                  data: dailyTotal,
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1
                }
              ]
            });
          })

        axios.post("https://bmserver.ccsoftwares.com/Sales/getItemWiseSalesCount",data).then((res) =>{
            //console.log(res.data)
            // setStock(res.data)
            const salesData = res.data.data;
            const dates = salesData?.map(item => item.product_name);
            const dailyTotal = salesData?.map(item => item.total_count);
        
            setChartData2({
              labels: dates,
              datasets: [
                {
                  label: 'Item Wise Sales',
                  data: dailyTotal,
                  backgroundColor: 'rgba(56, 246, 17, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1
                }
              ]
            });
          })


          axios.post("https://bmserver.ccsoftwares.com/Sales/TotalSales",data).then(res => {
            if (res.data.total_sales) {

              res.data.total_sales_by_store.map((e,i) =>{
                if(e.store === "Galigamuwa"){
                  setTotalGal(e.total_sales)
                }else{
                  setTotalWar(e.total_sales)

                }
              })
              setTotal(res.data.total_sales)
              
            }else{
              setTotalWar(0)
              setTotalGal(0)
              setTotal(0)
              
            }
          })



    }, [endDate, startDate, store])

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'LKR', // Change to your desired currency code
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      }).format(value);
  };
    

    

  return (
    <Grid container spacing={0}>
        <Grid md = {4} xs = {12}>
            <Grid item md={6} xs={12}>
                <TextField
                    placeholder=''
                    type='date'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Date from "
                    value={startDate}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                    >
                </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    placeholder=''
                    type='date'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Date To "
                    value={endDate}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => setEndDate(e.target.value)}
                    >
                </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
            <InputLabel id="Store-label">Store</InputLabel>
            <Select
                labelId="Store-label"
                label="Store"
                name="Store"
                value={store}
                onChange={(e) => setStore(e.target.value)}
                style={{ marginBottom: '20px' }}
                fullWidth
                >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"Warakapola"}>Warakapola</MenuItem>
                <MenuItem value={"Galigamuwa"}>Galigamuwa</MenuItem>
            </Select>
            </Grid>
            <Grid xs = {12} md = {6}>
              Total : {formatCurrency(total)}
            </Grid>
            <Grid xs = {12} md = {6}>
              Warakapola : {formatCurrency(totalWar)}
            </Grid>
            <Grid xs = {12} md = {6}>
              Galigamuwa : {formatCurrency(totalGal)}
            </Grid>
        </Grid>



        <Grid md = {6} xs = {12} >
          

            <Grid xs = {12} md = {12}>
              <Bar data={chartData} options={{maintainAspectRatio : true}}/>
            </Grid>
            <Grid xs = {12} md = {12}>
              <Bar data={chartData2} options={{maintainAspectRatio : true}}/>
            </Grid>
        
        </Grid>

        </Grid>






  )
}

export default SalesAnalysis