import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from 'simple-react-modal'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Supplier() {
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        supplierID: '',
        email: ''
    });
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [updateID, setUpdateID] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);

    const navigate = useNavigate()
    useEffect(() => {
        if(!localStorage.getItem("user")){
            navigate("/Login")
          }
        
    }, [])

    useEffect(() => {
        getSuppliers();
    }, []);

    const handleCloseModal = () => {
        setModalOpen(false);
        resetFormData();
        setIsUpdate(false);
    };

    const resetFormData = () => {
        setFormData({
            name: '',
            mobileNumber: '',
            supplierID: '',
            email: ''
        });
        setUpdateID('');
    };

    const handleSave = async () => {
        try {
            if (isUpdate) {
                const upDateModel = {
                    name: formData.name,
                    mobileNumber: formData.mobileNumber,
                    updateID: updateID
                };
                const res = await axios.put('https://bmserver.ccsoftwares.com/Supplier/updateSupplier', upDateModel);
                if (res.data.status === 'Success') {
                    toast.success('Supplier Updated Successfully');
                    handleCloseModal();
                    getSuppliers();
                }
            } else {

                const saveModel = {
                    name: formData.name,
                    mobileNumber: formData.mobileNumber,
                    supplierID: formData.supplierID,
                    email: formData.email
                };
                
                await axios.post('https://bmserver.ccsoftwares.com/Supplier/addSupplier', saveModel).then((res) =>{

                  if (res.data.massage === 'Success') {
                      toast.success('Supplier Added Successfully');
                      handleCloseModal();
                      getSuppliers();
                  }
                })
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again.');
        }
    };

    const getSuppliers = async () => {
        try {
            const res = await axios.get('https://bmserver.ccsoftwares.com/Supplier/getSupplier');
            setAllSuppliers(res.data);
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while fetching suppliers.');
        }
    };

    const deleteSupplier = async (deleteID) => {
        try {
            await axios.delete(`https://bmserver.ccsoftwares.com/Supplier/DeleteSupplier/${deleteID}`);
            toast.success('Supplier Deleted Successfully');
            getSuppliers();
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while deleting the supplier.');
        }
    };

    const editSupplier = (updateRow) => {
        setUpdateID(updateRow._id);
        setIsUpdate(true);
        setFormData({
            name: updateRow.name,
            mobileNumber: updateRow.mobileNumber, 
            email: updateRow.email,
            supplierID: updateRow.SupplierID
        });
        setModalOpen(true);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px', marginTop: '25px' }}>
                    <h1>Supplier Management</h1>
                    <Button variant="outlined" onClick={() => setModalOpen(true)} sx={{ color: 'black', borderColor: 'black', marginLeft: '35vw ' }}>Add New Supplier</Button>
                </div>
                <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '80%' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Supplier ID</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Supplier Name</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Supplier Mobile Number</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allSuppliers.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell align="center">{row.SupplierID}</TableCell>
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.mobileNumber}</TableCell>
                                    <TableCell align="center">{row.email}</TableCell>
                                    <TableCell align="center">
                                        <IconButton>
                                            <CreateIcon sx={{ color: 'black' }} onClick={() => editSupplier(row)} />
                                        </IconButton>
                                        &nbsp;
                                        <IconButton>
                                            <DeleteIcon sx={{ color: 'black' }} onClick={() => deleteSupplier(row._id)} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal
                    show={modalOpen} containerStyle={{ borderRadius: '10px' }}>
                    <div style={{ height: '70vh' }}>
                        <IconButton style={{ display: 'flex', marginLeft: 'auto' }} onClick={handleCloseModal} sx={{ color: 'red' }}>
                            <HighlightOffIcon />
                        </IconButton>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Supplier Name</Form.Label>
                                <Form.Control placeholder="Enter Supplier Name" name='name' value={formData.name} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Supplier Mobile Number</Form.Label>
                                <Form.Control name='mobileNumber' value={formData.mobileNumber} onChange={handleChange} placeholder="Enter Mobile Number" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Supplier ID</Form.Label>
                                <Form.Control name="supplierID" value={formData.supplierID} onChange={handleChange} placeholder="Enter Supplier ID" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type='email' name="email" value={formData.email} onChange={handleChange} placeholder="Enter Email" readOnly={isUpdate} />
                            </Form.Group>
                            <Button variant="contained" onClick={handleSave}>
                                {isUpdate ? 'Update' : 'Save'}
                            </Button>
                        </Form>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default Supplier;
