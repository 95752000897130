import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { drawerRoute } from '../Data/Data'
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import '../Fonts/Kanit.css'
import '../Fonts/Fjalla.css'
import logo from '../Assests/bloommark.png'

export default function Navbar() {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });
  const [open, setOpen] = React.useState(false);
  const [iconChange, setIconChange] = React.useState(false)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({
      ...state,
      [anchor]: open
    });

    setOpen(true)
  };

  const toggleDrawerClose = (anchor, close) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({
      ...state,
      [anchor]: close
    });
    setOpen(false)

  };

  const toggleDrawerOnClick = (anchor, close) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({
      ...state,
      [anchor]: close
    });
    setOpen(false)
    setIconChange(false)
  };

  const handleClick = () => {
    navigate("Login")
  }
  const handleClickLogout = () => {

    const result = window.confirm("Are you sure?");

    if (result) {
      //console.log("User clicked OK");
      localStorage.clear()
      window.location.reload()
      navigate("Login")
    } 

  }

  const list = (anchor) => (
    <Box
      sx={{ width: 250, background: 'Black', height: '100%', }}
      role="presentation"
      onClick={toggleDrawerOnClick(anchor, false)}
      onKeyDown={toggleDrawerOnClick(anchor, false)}
    >
      <IconButton aria-label="menu open">
        <MenuOpenIcon onClick={toggleDrawerClose(anchor, false)} sx={{ color: 'white', fontSize: '40px' }} />
      </IconButton>
      <center>
        <img
          src={logo}
          width='60%'
          height='auto'
          alt='companyLogo'
          draggable={false}
        />
        <Typography sx={{ fontSize: '25px', textTransform: 'uppercase', fontFamily: 'Fjalla One, sans-serif', fontWeight: 'bold', color: 'white' }}>BloomMark</Typography>
      </center>
      <List>
        {drawerRoute.map((text, index) => (
          <ListItem key={index} disablePadding>
            <Link
              to={text.path}
              style={{
                textDecoration: 'none',
                color: 'black',
              }}
            >
              <ListItemButton sx={{ width: 250 }}>
                <ListItemIcon sx={{ color: 'white' }}>
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontFamily: 'Kanit, sans-serif'
                  }}
                  primary={text.name}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  return (
    <div >
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box sx={{ background: 'Black', position: 'fixed', width: '100%', zIndex: '1', overflow: 'hidden' }}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <IconButton aria-label="menu open">
                  <MenuIcon onClick={toggleDrawer(anchor, true)} sx={{ color: 'white', fontSize: '40px' }} />
                  {/* {open === false && iconChange === false ? <MenuIcon onClick={toggleDrawer(anchor, true)} sx={{ color: 'white', fontSize: '40px' }} />
                    : <MenuOpenIcon onClick={toggleDrawerClose(anchor, false)} sx={{ color: 'white', fontSize: '40px' }} />} */}
                </IconButton>
              </Grid>
              <Grid item md={6} xs={6} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '1vw' }}>
                {localStorage.getItem("user") ? (

                <Button onClick={handleClickLogout} variant="text" style={{ fontWeight: 'bold', color: 'White',backgroundColor : 'red' }}>
                  {JSON.parse(localStorage.getItem("loggedDetails")).userName} 
                  <img src = {require('../Assests/user.png')} alt = 'user' width = {25} style={{border : '2px solid white',borderRadius : 50,marginLeft : 10,marginRight : 10}}/> 
                 LOGOUT
                </Button>
                ):<>
                <Button onClick={handleClick} variant="text" style={{ fontWeight: 'bold', color: 'white' }}>
                  Login
                </Button>
                
                </>}
              </Grid>
            </Grid>

          </Box>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawerOnClick(anchor, false)}
            sx={{ height: '300px' }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))
      }
    </div >
  )
}
