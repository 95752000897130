import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Input, InputLabel, Select } from '@mui/material';
import axios from 'axios';
import {SyncLoader} from 'react-spinners'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {
    MenuItem,
    Grid,
    TextField,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: 5,
  };



function Grn() {

    const [grnNumber,setGrnNumber] = useState('')
    const [rows,setRows] = useState([])
    const [loading,setLoading] = useState(true)

    const navigate = useNavigate()

    useEffect(() => {
        if(!localStorage.getItem("user") || (JSON.parse(localStorage.getItem("loggedDetails")).role).toUpperCase() !== 'ADMIN'){
            navigate("/Login")
          }
        
    }, [])
    

    useEffect( () => {

       axios.get("https://bmserver.ccsoftwares.com/Grn/getNextId").then(res =>{
          const newNum = parseInt(res.data.data.grn_no.split('-')[1])+1;
          setGrnNumber('GR-'+newNum.toString().padStart(2, '0'))
      }).catch(e =>{
        setGrnNumber('GR-01')
      })

    }, [rows])

    const initialFormData = {
        grn_no: grnNumber,
        Supplier: 'Sample',
        Cashier: (JSON.parse(localStorage.getItem("loggedDetails"))?.userName.toUpperCase()),
        grn_date: '',
        Total: '',
        paid: '',
        pending: '',
        item_id: '',
        item_name: '',
        quantity: '',
        discount: 0,
        measure: '',
        payment_method: '',
    };
      const [open, setOpen] = useState(false);
      const handleOpen = () => {setFormData(initialFormData);setOpen(true)};
      const handleClose = () => setOpen(false);
      const [items,setItems] = useState([]);
      const [supplier,setSupplier] = useState([]);

      useEffect(() => {

        axios.get('https://bmserver.ccsoftwares.com/item/').then((res => {
            if (res.data.length > 0) {
                setItems(res.data)
            } else {
                setItems([]);
            }
        }))

        axios.get('https://bmserver.ccsoftwares.com/Supplier/getSupplier').then((res => {
            if (res.data.length > 0) {
                setSupplier(res.data)
            } else {
                setSupplier([]);
            }
        }))



      }, [])
      





      const [formData, setFormData] = useState({
        grn_no: '',
        Supplier: '',
        Cashier: (JSON.parse(localStorage.getItem("loggedDetails"))?.userName.toUpperCase()),
        grn_date: '',
        Total: '',
        paid: '',
        pending: 0,
        item_id: '',
        item_name: '',
        quantity: '',
        discount: '',
        measure: '',
        payment_method: '',
    });

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission here
        //console.log(formData);

        const newDataForm = {
            grn_no: formData.grn_no,
            Supplier: formData.Supplier,
            Cashier: formData.Cashier,
            grn_date: formData.grn_date,
            Total: formData.Total,
            paid: formData.paid,
            pending: formData.Total - formData.paid,
            item_id: formData.item_id,
            item_name: formData.item_name,
            quantity: formData.quantity,
            discount: formData.discount,
            measure: formData.measure,
            payment_method: formData.payment_method,
        }

        const newDataFormStock = {
            itemName: formData.item_name,
            itemCategories : 0,
            itemCategoryName : "-",
            itemCodeNew: "-",
            qty : formData.quantity,
            store: JSON.parse(localStorage.getItem("loggedDetails")).shop ,
          }


          //console.log(newDataFormStock)
        await axios.post("https://bmserver.ccsoftwares.com/Stock/incStock",newDataFormStock).then(res =>{
            //console.log(res.status)
            axios.post("https://bmserver.ccsoftwares.com/Grn/addGrn",newDataForm).then(res =>{
                //console.log(res.status)
                handleClose()
            }).catch(e =>{
                alert(e)
            })
              handleClose()
          }).catch(e =>{
              alert(e)
          })





    };


    useEffect(() => {

        axios.get("https://bmserver.ccsoftwares.com/Grn").then(res =>{
            setRows(res.data.data)
            setLoading(false)
        })


    }, [rows])

    const decreaseStock = async(e) =>{
        const userConfirmed = window.confirm("Are you sure you want to delete this GRN?");
    
        if (userConfirmed) {

            const updateData = {
            itemName :  e.item_name,
            qty : e.quantity,
            store : JSON.parse(localStorage.getItem("loggedDetails")).shop
        }

        const deleteId = {
            delId : e._id,
            name : 'sample name for testing'
        }


        //console.log(updateData)


        await axios.post("https://bmserver.ccsoftwares.com/Stock/decStock",updateData).then(res =>{
            //console.log(res.status)
        axios.post(`https://bmserver.ccsoftwares.com/Grn/deleteGrn`,deleteId).then(res =>{
            //console.log(res.status)
            handleClose()
        }).catch(e =>{
            alert(e)
        })
            
        }).catch(e => {
            alert(e)

        })
    }

    }
    

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px', marginTop: '25px' }}>
                    <h1>GRN (Good Receive Note)</h1>
                    <Button variant="outlined" onClick={handleOpen} sx={{ color: 'black', borderColor: 'black', marginLeft: '35vw ' }}>Create New GRN</Button>
                </div>
                {loading ? (
                <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',height : '100vh'}}>
                    <SyncLoader color="#1e1e1e" />
                </div>
                ) : (
                <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '80%' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>GRN no</b></TableCell>
                                <TableCell><b>Supplier Name</b></TableCell>
                                <TableCell align="right"><b>Item Name</b></TableCell>                               
                                <TableCell align="right"><b>Quantity</b></TableCell>
                                <TableCell align="right"><b>Measure</b></TableCell>
                                <TableCell align="right"><b>Total</b></TableCell>
                                <TableCell align="right"><b>Pending</b></TableCell>
                                <TableCell align="center"><b>Date</b></TableCell>
                                <TableCell align="center"><b>Actions</b></TableCell>
                            </TableRow>
                        </TableHead>
                    <TableBody>
                    {rows.map((row,i) => (
                    <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                        {row.grn_no}
                        </TableCell>
                        <TableCell component="th" scope="row">
                        {row.Supplier}
                        </TableCell>
                        <TableCell align="right">{row.item_name}</TableCell>
                        <TableCell align="right">{row.quantity}</TableCell>
                        <TableCell align="right">{row.measure}</TableCell>
                        <TableCell align="right">{row.Total}.00</TableCell>
                        <TableCell align="right">{row.pending}.00</TableCell>
                        <TableCell align="center">{row.grn_date.split('T')[0]}</TableCell>
                        <TableCell align="center"><IconButton onClick={() => {decreaseStock(row)}}><DeleteSweepIcon/></IconButton></TableCell>
                    </TableRow>
                    ))}
                </TableBody> 
               
                    </Table>
                </TableContainer>
                )}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="GRN-Model"
                    aria-describedby="good receive note"
                >
                <Box sx={style}>
                <form onSubmit={handleSubmit}>

                <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="GRN No"
                        name="grn_no"
                        value={formData.grn_no}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                        disabled
                    />
                    <TextField
                        fullWidth
                        label="Cashier"
                        name="Cashier"
                        value={formData.Cashier}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                        disabled
                    />
                    {/* <TextField
                        fullWidth
                        label="Supplier"
                        name="Supplier"
                        value={formData.Supplier}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                    /> */}
                    <InputLabel id="Supplier">Supplier</InputLabel>
                    <Select
                        labelId = "Supplier"
                        label="Supplier"
                        name="Supplier"
                        value={formData.Supplier}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        fullWidth
                    >
                        {supplier.map((e,i) => (
                            <MenuItem value={e.name}>{e.name}</MenuItem>
                        ))}
                    </Select>

                    <TextField
                        fullWidth
                        label="GRN Date"
                        name="grn_date"
                        type="date"
                        value={formData.grn_date}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ marginBottom: '20px' }}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Total"
                        name="Total"
                        type="number"
                        value={formData.Total}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Paid"
                        name="paid"
                        type="number"
                        value={formData.paid}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                    />
                    <InputLabel id="measure-label">Measure</InputLabel>
                    <Select
                        labelId="measure-label"
                        label="Measure"
                        name="measure"
                        value={formData.measure}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        fullWidth
                    >
                        <MenuItem value={"Each"}>Each</MenuItem>
                        <MenuItem value={"Dz"}>Dozens</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Pending"
                        name="pending"
                        type="number"
                        value={formData.Total - formData.paid}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        input={<Input readOnly />}
                    />
                <InputLabel id="Item">Item</InputLabel>
                    <Select
                        labelId = "Item"
                        id = "Item"
                        label="Item"
                        name="item_name"
                        value={formData.item_name}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        fullWidth
                    >
                        {items.map((e,i) => (
                            <MenuItem value={e.itemName}>{e.itemName}</MenuItem>
                        ))}
                       
                    </Select>
                    <TextField
                        fullWidth
                        label="Quantity"
                        name="quantity"
                        type="number"
                        value={formData.quantity}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Discount"
                        name="discount"
                        type="number"
                        value={formData.discount}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        required
                    />
    
                    <InputLabel id="Payment_method">Payment Method</InputLabel>
                    <Select
                        labelId = "Payment_method"
                        label="Payment method"
                        name="payment_method"
                        value={formData.payment_method}
                        onChange={handleChange}
                        style={{ marginBottom: '20px' }}
                        fullWidth
                    >
                        <MenuItem value={"Cash"}>Cash</MenuItem>
                        <MenuItem value={"Card"}>Card</MenuItem>
                    </Select>
                    
                </Grid>
            </Grid>
                    <Button variant="outlined" type='submit'  sx={{ color: 'black', borderColor: 'black'}} fullWidth>
                        Submit
                    </Button>
                    </form>
                    </Box>
                </Modal>
            </div></>

    )
}

export default Grn