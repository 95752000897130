import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, Grid, TextField } from '@mui/material';
import {SyncLoader} from 'react-spinners'


function Sales() {

  var currentDate = new Date();
  currentDate.setDate(1);
  var startDateOfMonth = currentDate.toISOString().split('T')[0];

  var todayDate = new Date().toISOString().split('T')[0];

    const[sales,setSales] = useState([])
    const [search,setSearch] = useState('')
    const [loading,setLoading] = useState(true)
    const [startDate,setStartDate] = useState(startDateOfMonth)
    const [endDate,setEndDate] = useState(todayDate)
    const [warakapolaTotal,setWarakapolaTotal] = useState();
    const [galigamuwaTotal,setGaligamuwaTotal] = useState();
    const [total,setTotal] = useState(0)




    useEffect(() => {
      
            
            axios.get("https://bmserver.ccsoftwares.com/Sales").then(res => {
              //console.log(res.data)
              setSales(res.data)
              setLoading(false)
            })

            const data = {
              from_date : startDate,
              to_date : endDate
            }

            //console.log(data)
            
            
            // axios.post("https://bmserver.ccsoftwares.com/Sales/TotalSales",data).then(res => {
            //   if (res.data.total_sales) {
            //     setWarakapolaTotal(res.data.total_sales_by_store[0].total_sales)
            //     setGaligamuwaTotal(res.data.total_sales_by_store[1].total_sales)
                
            //   }else{
            //     setWarakapolaTotal(0)
            //     setGaligamuwaTotal(0)
            //     setTotal(0)
                
            //   }
            // })

            axios.post("https://bmserver.ccsoftwares.com/Sales/setTotalSalesStorewise",data).then(res => {
              if (res.data) {
                setTotal(res.data.total_net)
                setWarakapolaTotal(res.data.store_sales[0].net_total)
                setGaligamuwaTotal(res.data.store_sales[1].net_total)
                
              }else{
                setWarakapolaTotal(0)
                setGaligamuwaTotal(0)
                setTotal(0)
                
              }
            })

             
    }, [endDate, startDate])

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'LKR', // Change to your desired currency code
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      }).format(value);
  };
    



  return (
    <>
    <div style={{backgroundColor  :'white',width : '100%',marginLeft : 10}}>
      <Grid container spacing={2}>

 
                <Grid item md={6} xs={12}>
                    <TextField
                        placeholder=''
                        type='date'
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Date from "
                        value={startDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => setStartDate(e.target.value)}
                        >
                    </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        placeholder=''
                        type='date'
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Date To "
                        value={endDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => setEndDate(e.target.value)}
                        >
                    </TextField>
                </Grid>
                </Grid>

        <TextField id="standard-basic" label="Search by Invoice No / Product name" variant="standard" onChange={(e) => {setSearch(e.target.value)}} fullWidth autoComplete="off"/>
    </div>
    {loading ? (
      <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',height : '100vh'}}>
        <SyncLoader color="#1e1e1e" />
      </div>
    ) : (
<>
    <Grid container style={{marginTop : 20}}>
      <Grid md = {4} xs = {12}>
        <Card style={{height : '100px',justifyContent : 'center',display : 'flex',alignItems : 'center'}}>
          <div style={{display : 'flex'}}>
       
            Selected period Net Total : 
        
      
           {formatCurrency(total)}
        
          </div>
        </Card>
      </Grid>
      <Grid md = {4} xs = {12}>
      <Card style={{height : '100px',justifyContent : 'center',display : 'flex',alignItems : 'center'}}>
 Galigamuwa Net Total :  {formatCurrency(galigamuwaTotal)} 
         
        </Card>
      </Grid>
      <Grid md = {4} xs = {12}>
      <Card style={{height : '100px',justifyContent : 'center',display : 'flex',alignItems : 'center'}}>

          Warakapola Net Total : 
           {formatCurrency(warakapolaTotal)} <br></br>
        </Card>
      </Grid>

    </Grid>

    <div style={{display : 'flex',justifyContent : 'center',marginTop : 70}}>
    <TableContainer component={Paper}>
      <Table aria-label="sales table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Invoice No</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Product Name</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Qty</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Cashier</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Gross Total</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Net Total</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Discount</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Shop</TableCell>
            <TableCell style={{fontWeight : 'bold'}}>Bill Date /Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.filter((element) => {
                          if (search === '') {
                              return element
                          }
                          else if ((element.invoice_no.toLowerCase()).includes(search.toLowerCase()) || (element.product_name.toLowerCase()).includes(search.toLowerCase()) ) {
                              return element
                              
                          }
                          
                      }).map((e, i) => (
            <TableRow key={i+1}>
              <TableCell>{i+1}</TableCell>
              <TableCell>{e.invoice_no}</TableCell>
              <TableCell>{e.product_name}</TableCell>
              <TableCell>{e.qty}</TableCell>
              <TableCell>{e.cashier}</TableCell>
              <TableCell>Rs.{e.gross_total}.00</TableCell>
              <TableCell>Rs.{e.net_total}.00</TableCell>
              <TableCell>Rs.{e.discount}.00</TableCell>
              <TableCell>{e.store}</TableCell>
              <TableCell>{e.bill_date.split('T')[0]} / {e.bill_time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
        </>
    )}



    </>


  )
}

export default Sales