import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import 'chart.js/auto';
import { Line, Pie } from 'react-chartjs-2';

function SecondStore() {
  const [product, setproduct] = React.useState('');
  const [stock,setStock] = React.useState([])
  const [item,setItems] = React.useState([])
  const [qty,setQty] = React.useState(0)

  const [formData, setFormData] = React.useState({
    itemName: '',
    itemCategories : '',
    itemCategoryName : '',
    itemCodeNew: '',
    qty : '',
    store: 'Galigamuwa',
    _id : ''
});


const [chartData, setChartData] = useState({
  labels: [],
  datasets: []
});



useEffect(() => {

  axios.get('https://bmserver.ccsoftwares.com/item/').then((res => {
      if (res.data.length > 0) {
          setItems(res.data)
      } else {
          setItems([]);
      }
  }))

}, [])


const [open, setOpen] = React.useState(false);
const handleOpen = (e) => {setFormData(e);setOpen(true)};
const handleClose = () => setOpen(false);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 5,
};




  const handleChangeproduct = (event) => {
    setproduct(event.target.value);
  };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

  useEffect(() => {

    axios.get("https://bmserver.ccsoftwares.com/Stock/getStockByStoreGaligamuwa").then((res) =>{
      //console.log(res.data)
      setStock(res.data)
      const salesData = res.data;
      const itemNames = salesData?.map(item => item.itemName);
      const counts = salesData?.map(item => item.qty);
  
      setChartData({
        labels: itemNames,
        datasets: [
          {
            label: 'Stock Balances',
            data: counts,
            // backgroundColor: 'rgba(75, 192, 192, 0.6)',
            // borderColor: 'rgba(75, 192, 192, 1)',
            // borderWidth: 1
          }
        ]
      });
    })



  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here
    //console.log(formData);

    const newDataForm = {
      itemName: formData.itemName,
      itemCategories : formData.itemCategories,
      itemCategoryName : formData.itemCategoryName,
      itemCodeNew: formData.itemCodeNew,
      qty : qty,
      store: formData.store,
    }

    //console.log(formData._id)

    const updateData = {
      itemName: formData.itemName,
      store: formData.store,
      qty : qty ,
      id : formData._id,
      udpateQty : formData.qty - qty
    }


    //console.log(updateData)



    
    
    await axios.put("https://bmserver.ccsoftwares.com/Stock/updateStock",updateData).then(res =>{
      //console.log(res.status)
        axios.post("https://bmserver.ccsoftwares.com/Stock/incStock",newDataForm).then(res =>{
          //console.log(res.status)
          alert("Stock updated")
            handleClose()
        }).catch(e =>{
            alert(e)
        })
    }).catch(e =>{
        alert(e)
    })

    

};
  



  return (
    <Grid container spacing={2}>
      <Grid xs = {12} md = {6}>
      Search By product <br></br><br></br>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Product</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={product}
          label="Product"
          onChange={handleChangeproduct}
        >
          <MenuItem value=''>All</MenuItem>
          {item.map((e,i) => (
                        <MenuItem value={e.itemName}>{e.itemName}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="right">Item name</TableCell>
            <TableCell align="right">Item Code</TableCell>
            <TableCell align="right">Stock</TableCell>
            <TableCell align="center">Store</TableCell>
            <TableCell align="center">Allocation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stock.filter((element) => {
                          if (product === '') {
                              return element
                          }
                          else if ((element.itemName.toLowerCase()).includes(product.toLowerCase()) || (element.itemName.toLowerCase()).includes(product.toLowerCase()) ) {
                              return element
                              
                          }
                          
                      }).map((row,i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {i+1}
              </TableCell>
              <TableCell align="right">{row.itemName}</TableCell>
              <TableCell align="right">{row.itemCodeNew}</TableCell>
              {row.qty < 0 ? (

              <TableCell align="right" style={{backgroundColor  :'red',color : 'white',fontSize : 17}}>{row.qty}</TableCell>
              ):
              <TableCell align="right">{row.qty}</TableCell>
              } 
              <TableCell align="right">{row.store}</TableCell>
              <TableCell align="center">
                {/* <Button variant="contained" onClick={({row}) => handleOpen(row)}>Allocate</Button> */}
                {/* <Button variant="contained" onClick={() => {handleOpen(row)}}>Allocate</Button> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    
      </Grid>
      <Grid xs = {12} md = {6}>
        <div style={{width : '100%',display : 'flex',justifyContent : 'center',alignItems : 'center',height : '50vh'}}>
        {/* <StockBarChart /> */}
        <Pie data={chartData} options={{maintainAspectRatio : false}}/>
        </div>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="GRN-Model"
        aria-describedby="good receive note"
    >
            <Box sx={style}>
            <form onSubmit={handleSubmit}>

            <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
                <TextField
                    fullWidth
                    label="Item Name"
                    name="Item Name"
                    value={formData.itemName}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    required
                    disabled
                />
                <TextField
                    fullWidth
                    label="Item Code"
                    name="Item Code"
                    value={formData.itemCodeNew}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    required
                    disabled
                />
                <div>
                Available For Allocation  :  {formData.qty} <br></br><br></br>
                </div>
                     
                <TextField
                    fullWidth
                    label="Quantity"
                    name="quantity"
                    type="number"
                    onChange={(e) => {setQty(e.target.value)}}
                    style={{ marginBottom: '20px' }}
                    inputProps={{ max: formData.qty,min : 0}}
                    required
                />
              
                <InputLabel id="store">Store</InputLabel>
                <Select
                    labelId = "store"
                    label="Payment_method"
                    name="store"
                    value={formData.store}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    fullWidth
                    required
                >
                
                    <MenuItem value={"Galigamuwa"}>Galigamuwa</MenuItem>

                </Select>
                
            </Grid>
        </Grid>
                <Button variant="outlined" type='submit'  sx={{ color: 'black', borderColor: 'black'}} fullWidth>
                    Allocate
                </Button>
                </form>
                </Box>
            </Modal>
    </Grid>
    
  )
}

export default SecondStore