import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Login() {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const Navigate = useNavigate();

  //const { user, setUser } = UserDetails();

  const validate = async (e) => {

    // alert('Pressed')
    e.preventDefault();

    const data = {
      username,
      password
    }

    await axios.post("https://bmserver.ccsoftwares.com/authUser/login", data).then(res => {
      localStorage.setItem('user', res.data.token);
      localStorage.setItem('loggedDetails', JSON.stringify(res.data.user));
      //setUser(res.data.user)
      //console.log(res.data.user)
      Navigate('/')
    }).catch(err => {
      alert(
        "User name or Password Incorrect !!!"
      )
    })
  }

  return (
    <>
      <style>{`
        .form-container {
          width: 450px;
          height: 500px;
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 20px 30px;
          margin: 70px auto 0;
        }

        .title {
          text-align: center;
          font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
                "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
          margin: 10px 0 30px 0;
          font-size: 28px;
          font-weight: 800;
        }

        .form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 18px;
          margin-bottom: 15px;
        }

        .input {
          border-radius: 20px;
          border: 1px solid #c0c0c0;
          outline: 0 !important;
          box-sizing: border-box;
          padding: 12px 15px;
        }

        .page-link {
          text-decoration: underline;
          margin: 0;
          text-align: end;
          color: #747474;
          text-decoration-color: #747474;
        }

        .page-link-label {
          cursor: pointer;
          font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
                "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
          font-size: 9px;
          font-weight: 700;
        }

        .page-link-label:hover {
          color: #000;
        }

        .form-btn {
          padding: 10px 15px;
          font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
                "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
          border-radius: 20px;
          border: 0 !important;
          outline: 0 !important;
          background: #000;
          color: white;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        `}</style>

      <div className="form-container" onSubmit={validate}>
        <p className="title">Welcome to BLOOMMARK</p>
        <form className="form">
          <input
            type="text"
            className="input"
            placeholder="Enter User Name"
            onChange={(e) => { setUsername(e.target.value) }}
            style={{ marginBottom: "10px" }}
          />
          <input
            type="password"
            className="input"
            placeholder="Password"
            onChange={(e) => { setPassword(e.target.value) }} />
          <p className="page-link">
            <span className="page-link-label">Forgot Password?</span>
          </p>
          <button className="form-btn" type = 'submit'>Log in</button>
        </form>


      </div>
    </>
  );
}
