import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Heart from 'react-animated-heart'

function Items(props) {
    
    const[Good,setGoods] = useState([])

    useEffect(() => {
        setGoods(props.items)
        // //console.log(props.items)

    }, [props.items])


    const handleClick = (e) =>{
      const itemDetails = e;
      itemDetails.name = e.itemName
      itemDetails.Price = e.sellingPrice
      props.addToBill(itemDetails)
    }

    const [isClick, setClick] = useState(false);
    const [newItem,setNewItem] = useState([])
    const [change,setChange] = useState(null)


    useEffect(() => {

      if(change != null){

        const data = {
          id : change._id,
          fav : (change.isfavourite === 0  ? 1 : 0)
        }

        // //console.log(data)

              
        axios.put("https://bmserver.ccsoftwares.com/item/addToFavourite",data).then((res) =>{
              //console.log(res)
              props.trig(Math.random())
            }).catch (e =>{
              //console.log(e)
            })
          }  
          
        }, [change, props])
        
        
        
        const setFavourite = async (e) =>{
          setChange(e)
    }
     
    return (
            <div style={{ display: 'flex', flexWrap: 'wrap',height : '63vh',justifyContent:'left' }}>
            {Good.map((item, index) => (
              <>
              <div key={index} style={{ width: '160px',backgroundColor : 'white',margin:5}}>
                <div >
                  <div style={{display : 'block',border: '1px solid #ccc',backgroundColor :'white',borderRadius : 5}}>
                    <div style={{marginBottom : -40,marginTop : -30,marginLeft : -30,display : 'relative'}}>
                    <Heart isClick={item.isfavourite} onClick={() => setFavourite(item)}/>
                    </div>
                    <div onClick={() => {handleClick(item)}} style={{ border: '1px solid #ccc', padding: '10px',height : '180px',borderRadius:5,display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'white',justifyContent : 'space-around'}}>
                    
                    <img  src={'https://bmserver.ccsoftwares.com/Assets/Images/'+item.image} style={{objectFit : 'fill',width : 100,height:100,borderRadius:20}} alt = '' draggable = {false}></img>
                    <div style={{fontWeight : 'bold'}}>Rs.{item.sellingPrice}.00</div>
                    {item.itemName.length < 10 ? (<>
                    <div style={{fontWeight : 'bold',fontSize : 18}}>{item.itemName}</div>
                    </>) : (<>
                    <div style={{fontWeight : 'bold',fontSize : 12}}>{item.itemName}</div>
                    </>)}
                    </div>
                  </div>
                </div>
              </div>
              </>
            ))}
          </div>

        )
}

export default Items