import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupIcon from '@mui/icons-material/Group';
import BadgeIcon from '@mui/icons-material/Badge';
import ArchiveIcon from '@mui/icons-material/Archive';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';

export const drawerRoute = [
    {
        id: 1,
        name: 'Home',
        path: '/',
        icon: <HomeIcon />
    },
    {
        id: 2,
        name: 'Billing',
        path: '/billing',
        icon: <ReceiptIcon />
    },
    {
        id: 3,
        name: 'Item',
        path: '/itemCategory',
        icon: <CategoryIcon />
    },
    {
        id: 3,
        name: 'Category',
        path: '/Category',
        icon: <CategoryIcon />
    },
    {
        id: 4,
        name: 'Users',
        path: '/User',
        icon: <GroupIcon />
    },
    {
        id: 5,
        name: 'Customer',
        path: '/Customer',
        icon: <PersonAddIcon />
    },
    {
        id: 6,
        name: 'Supplier',
        path: '/Supplier',
        icon: <BadgeIcon />
    },
    {
        id: 7,
        name: 'GRN',
        path: '/Grn',
        icon: <EditNoteIcon />
    },
    {
        id: 8,
        name: 'Sales',
        path: '/Sales',
        icon: <MonetizationOnIcon />
    },
    {
        id: 9,
        name: 'Stock',
        path: '/Stock',
        icon: < ArchiveIcon/>
    },
    {
        id: 10,
        name: 'Return',
        path: '/Return',
        icon: < AssignmentReturnIcon/>
    },


]