import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Modal from 'simple-react-modal'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, TableBody } from '@mui/material';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import { toast } from 'react-toastify';

function Customer() {

    const [modalOpen, setModalOpen] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        mobileNumber: '',
        gender: '',
        email: ''
    });
    const [gender, setgender] = useState();
    const [allCustomers, setallCustomers] = useState([]);
    const [updateID, setUpdateID] = useState();
    const [isUpdate, setIsUpdate] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
        getCustomers();
        setFormData({
            ...formData,
            name: '',
            mobileNumber: '',
            gender: '',
            email: ''
        })
        setgender();
        if (isUpdate) {
            setIsUpdate(false)
        }
    };

    useEffect(() => {
        getCustomers();
    }, [])

    const handleSave = async () => {
        if (isUpdate) {
            const upDateModel = {
                name: formData.name,
                mobileNumber: formData.mobileNumber,
                updateID: updateID
            }
            axios.put('https://bmserver.ccsoftwares.com/Customer/updateCustomer', upDateModel).then((res) => {
                //console.log("res::> ", res.data)
                if (res.data.status == 'Success') {
                    setModalOpen(false);
                    getCustomers();
                    setFormData({
                        ...formData,
                        name: '',
                        mobileNumber: '',
                        gender: '',
                        email: ''
                    })
                    setgender();
                    setIsUpdate(false);
                    toast.success('Customer Updated Successfully');
                }
            })
        } else {
            try {
                const saveModel = {
                    name: formData.name,
                    mobileNumber: formData.mobileNumber,
                    gender: gender,
                    email: formData.email
                }
                await axios.post('https://bmserver.ccsoftwares.com/Customer/addCustomers', saveModel).then((res) => {
                    if (res.data.massage == 'Success') {
                        setFormData({
                            ...formData,
                            name: '',
                            mobileNumber: '',
                            email: ''
                        })
                        setgender();
                        handleCloseModal();
                    }
                });
            } catch (error) {
                console.error('Error saving customer:', error);
            }
        }
    };

    function getCustomers() {
        axios.get('https://bmserver.ccsoftwares.com/Customer/getCustomers').then((res) => {
            if (res.data.length > 0) {
                setallCustomers(res.data);
            }
        });
    }

    function DeleteCustomer(deleteID) {
        axios.delete(`https://bmserver.ccsoftwares.com/Customer/DeleteCustomer/${deleteID}`).then((res) => {
            if (res) {
                getCustomers();
            }
        })
    }

    function EditCustomer(updateRow) {
        setUpdateID(updateRow._id);
        setIsUpdate(true);
        setFormData({
            ...formData,
            name: updateRow.name,
            mobileNumber: updateRow.mobileNumber,
            email: updateRow.email
        })
        setgender(updateRow.gender)
        setModalOpen(true)
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <><style>
            {`
            @media (max-width: 600px) {
              div {
                height: auto;
              }
              h1 {
                font-size: 24px;
              }
              button {
                margin-left: 0;
                margin-top: 10px;
              }
            }
          `}
        </style>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px', marginTop: '25px' }}>
                    <h1>Customer Management</h1>
                    <Button variant="outlined" onClick={() => {
                        setModalOpen(true)
                    }} sx={{ color: 'black', borderColor: 'black', marginLeft: '35vw ' }}>Add New Customer</Button>
                </div>
                <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '80%' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Gender</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Mobile Number</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                <TableCell align='center' sx={{ fontWeight: 'bold' }}>Action</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allCustomers.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{row.name}</TableCell>
                                    <TableCell align="center">{row.gender}</TableCell>
                                    <TableCell align="center">{row.mobileNumber}</TableCell>
                                    <TableCell align="center">{row.email}</TableCell>
                                    <TableCell align="center">
                                        <IconButton>
                                            <CreateIcon sx={{ color: 'black' }} onClick={() => EditCustomer(row)} />
                                        </IconButton>
                                        &nbsp;
                                        <IconButton>
                                            <DeleteIcon sx={{ color: 'black' }} onClick={() => DeleteCustomer(row._id)} />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal
                    show={modalOpen} containerStyle={{ borderRadius: '10px' }}>
                    <div style={{
                        //   width: '50vh',
                        height: '70vh',

                    }}>
                        <IconButton style={{ display: 'flex', marginLeft: 'auto' }} onClick={handleCloseModal} sx={{ color: 'red' }}>
                            <HighlightOffIcon />
                        </IconButton>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name </Form.Label>
                                <Form.Control placeholder="Enter User Name" name='name' value={formData.name} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control name='mobileNumber' value={formData.mobileNumber} onChange={handleChange} placeholder="Enter Mobile Number" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Gender</Form.Label>
                                {isUpdate ?
                                    <Form.Control value={gender} readOnly={isUpdate} />
                                    :
                                    <Form.Select aria-label="Select Gender" onChange={(e) => setgender(e.target.value)} value={gender}>
                                        <option disabled selected>Select Gender</option>
                                        <option value={'Male'}>Male</option>
                                        <option value={'Female'}>Female</option>
                                    </Form.Select>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" value={formData.email} onChange={handleChange} placeholder="Enter Email" readOnly={isUpdate} />
                            </Form.Group>
                            <Button variant="contained" onClick={handleSave}>
                                {isUpdate ? 'Update' : 'Save'}
                            </Button>
                        </Form>
                    </div>
                </Modal>
            </div></>

    )
}

export default Customer;