import React from 'react'

function Home() {
    return (
        <div style={{ display: 'flex', height: 'calc(91vh - 1px)', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%)', marginTop: '-3vh' }}>
            <div style={{ display: 'block', justifyContent: 'center', textAlign: 'center' }}>
                <img src={require('../Assests/bloommark2.png')} alt='mainLogo' draggable={false} />
                <div style={{ fontSize: 50, fontWeight: 'bold', color: 'white' }}>BLOOMMARK</div>
            </div>
        </div>
    )
}

export default Home