// src/components/Home.js
import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print'
import { useReactToPrint } from 'react-to-print';
// import NumPad from 'react-numpad';
import TableTabs from '../../Components/TableTabs';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
function MainBilling() {

  const [isHovering, setIsHovering] = useState(false);
  const [bills, setBills] = useState([''])
  const [paymentMethod, setPaymentMethos] = useState('Cash')
  const [cash, setCash] = useState(0)
  const [open, setOpen] = useState(false);
  const [openDisc, setOpenDisc] = useState(false);
  const [discount, setDiscount] = useState(0)
  const [tempDisc, setTempDisc] = useState(0)
  const [invno, setInvNo] = useState()
  const [items, setItems] = useState([])
  const [total, setTotal] = useState(0)
  const [metaData, setMetaData] = useState({ user: JSON.parse(localStorage.getItem("loggedDetails"))?.userName,Total: total, Discount: discount,Method: paymentMethod, Amount: paymentMethod === 'Card' ? total : cash })
  const [loading,setLoading] = useState(true)
  
  const navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem("user")){
        navigate("/Login")
      }


      axios.get('https://bmserver.ccsoftwares.com/Sales').then(res =>{
        if (res.data.length < 1){
          setInvNo(1)
        }else{
          console.log(res.data.length)
          setInvNo(res.data.length)
        }
      })
    
}, [])
  
  

  const getMax = async () =>{
    
    
        await axios.get('https://bmserver.ccsoftwares.com/Sales').then(res =>{
          if (res.data.length < 1){
            setInvNo(1)
          }else{
            console.log(res.data.length)
            setInvNo(res.data.length)
          }
        })
  }

  


  




  const handleOpen = () => {
    getMax()
    setOpen(true);
    // setCash(0)
  };
  const handleOpenDisc = () => {
    setOpenDisc(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseDisc = () => setOpenDisc(false);

  const plusBtn = () => {
    setBills([...bills, ''])
  }

  const styles = {
    mainContainer :{
      backgroundColor : '#F5F5F5',
      width:'100%',
      // display : 'flex',
      justifyContent : 'center',
      margin : 10,
    },
    RightContainer : {
      backgroundColor :'white',
      // width : '60%',
      // width : '1050px',
      // height : '85vh',
      // padding : 10,
      // marginTop : -20,
      // boxShadow: '1px 1px 10px #1e1e1e',
      borderRadius: 5,
      // marginLeft : 20
    },
    sidePanel: {
      // backgroundColor : 'yellow',
      // marginRight : -10
    },
    sidepnlBtn: {
      // boxShadow: '1px 1px 10px #1e1e1e'
      borderRadius: 10
      , display: 'flex'
      , flexDirection: 'column',
      alignItems: 'center'
    },
    BillBtn: {
      boxShadow: '1px 1px 10px #1e1e1e',
      backgroundColor: isHovering ? 'white' : 'white',
      height: 50,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10

    },
    PlusBtn: {
      boxShadow: '1px 1px 10px #1e1e1e',
      width: 35,
      borderRadius: 10
    },
    deleteBtn: {
      width: 25,
      marginLeft: 20,

    },
    billTable:{
      backgroundColor :'white',
      // width : '490px',
      height : '60vh',
      padding : 10,
      // boxShadow: '1px 1px 10px #1e1e1e',
      borderRadius : 5,
      display : 'flex',
      flexDirection : 'column',
      borderTopLeftRadius : 0,
      justifyContent : 'space-between',
      overflow : 'auto',
      // marginLeft : 50,
      
    },
    paymentSelect: {
      width: '10wh',
      backgroundColor: 'white',
      padding: 10,
      boxShadow: '1px 1px 10px #1e1e1e',
      borderRadius: 5,
      marginTop: 20

    },
    style: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // width: 400,
      bgcolor: 'background.paper',
      border: '0px solid #000',
      boxShadow: 24,
      p: 4,
    }

    

  }



  useEffect(() => {

    var Total = 0.00

    for (let i = 0; i < items.length; i++) {
      Total = Total + parseFloat(items[i].Price) * parseFloat(items[i].qty)
    }

    setTotal(Total)

  }, [items])


  const addToBill = (e) => {
    if (items.length > 0) {
      let found = false;

      items.forEach((item, i) => {
        if (e.name === item.name) {
          //console.log(item.qty)
          item.qty += 1;
          found = true;
          const updateItem = [...items];
          setItems(updateItem);
        }
      });

      if (!found) {
        const updateItem = [...items, e];
        setItems(updateItem);
      }
    } else {
      const updateItem = [...items, e];
      setItems(updateItem);
    }

    codeRef.current.focus();

  }


  const resetBill = () => {

    getMax()

    items.forEach((item, i) => {
      //console.log(item.qty)
      item.qty = 1;
      const updateItem = [...items];
      setItems(updateItem);
    });
    setIsHovering(false);
    setBills([''])
    setPaymentMethos('Cash')
    setCash()
    setOpen(false);
    setOpenDisc(false);
    setDiscount(0)
    setTempDisc(0)
    setItems([])
    setTotal(0)
    setMetaData({ Total: total, Discount: discount, user: JSON.parse(localStorage.getItem("loggedDetails"))?.userName, Method: paymentMethod, Amount: paymentMethod === 'Card' ? total : cash })
    codeRef.current.focus();
  }


  const printsRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => printsRef.current,
  })


  const currentTime = new Date();
  const hours = formatTwoDigits(currentTime.getHours());
  const minutes = formatTwoDigits(currentTime.getMinutes());
  const seconds = formatTwoDigits(currentTime.getSeconds());

  function formatTwoDigits(value) {
    return value < 10 ? `0${value}` : value;
  }

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = formatTwoDigits(currentDate.getMonth() + 1); // Months are zero-based, so we add 1
  const day = formatTwoDigits(currentDate.getDate());
  const hour = formatTwoDigits(currentDate.getHours());
  const minute = formatTwoDigits(currentDate.getMinutes());
  const second = formatTwoDigits(currentDate.getSeconds());
  
  // //console.log(metaData)

  useEffect(() => {
    setMetaData({ Total: total, Discount: discount, user: JSON.parse(localStorage.getItem("loggedDetails"))?.userName, Method: paymentMethod, Amount: paymentMethod === 'Card' ? total : cash })
  }, [bills, cash, discount, paymentMethod, total])

  const toPrint = () => {

    
    items.map((e, i) => {
      const model = {
        invoice_no: `${year}${month}${day}${hour}BM${invno}`,
        cashier: metaData.user,
        bill_date: `${year}-${month}-${day}`,
        bill_time: `${hours}:${minutes}`,
        product_name: e.name,
        qty: parseInt(e.qty),
        unit_price: e.Price,
        gross_total: metaData.Total,
        discount: metaData.Discount,
        net_total: metaData.Total - metaData.Discount,
        paymentMethod: metaData.Method,
        cash_amount: metaData.Amount,
        Balance: metaData.Amount - (metaData.Total - metaData.Discount),
        Item_ID: e._id,
        Shift_ID: 'morning',
        product_category_id : e.itemCategories, 
        store : JSON.parse(localStorage.getItem("loggedDetails"))?.shop

      }

      //console.log(model)
      
      const stockupdateData = {
        itemName :  e.name,
        store: JSON.parse(localStorage.getItem("loggedDetails"))?.shop ,
        qty: parseInt(e.qty),

      }


      axios.post('https://bmserver.ccsoftwares.com/Sales/addSales',model).then((res) =>{
        //console.log(res)
        axios.put('https://bmserver.ccsoftwares.com/Stock/updateStockbyname',stockupdateData).then((res) =>{
          //console.log(res)
        }).catch(e =>{
          //console.log(e)
        })
      }).catch(e =>{
        //console.log(e)
      })




    })



    handlePrint()
    resetBill()



  }


  const changeQtyMinus = (e) => {
    if (items.length > 0) {
      let found = false;

      items.forEach((item, i) => {
        if (e.name === item.name) {
          //console.log(item.qty)
          item.qty -= 1;
          found = true;
          const updateItem = [...items];
          setItems(updateItem);
        }
      });

      if (!found) {
        const updateItem = [...items, e];
        setItems(updateItem);
      }
    } else {
      const updateItem = [...items, e];
      setItems(updateItem);
    }

  }
  const changeQtyPlus = (e) => {
    if (items.length > 0) {
      let found = false;

      items.forEach((item, i) => {
        if (e.name === item.name) {
          //console.log(item.qty)
          item.qty += 1;
          found = true;
          const updateItem = [...items];
          setItems(updateItem);
        }
      });

      if (!found) {
        const updateItem = [...items, e];
        setItems(updateItem);
      }
    } else {
      const updateItem = [...items, e];
      setItems(updateItem);
    }

  }

  const addDiscount = () => {
    setDiscount(parseInt(discount) + parseInt(tempDisc))
    handleCloseDisc()

  }

  const deleteItem = (e) => {
    setItems(items.filter(item => item.name !== e))
    if(items.length-1 < 1){
      setDiscount(0)
    }
    codeRef.current.focus();
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      alert(codeRef.current.value);
    }
  };


  const codeRef = useRef(null);


  return (
    <>
    <Grid container spacing={2} style={{ height: '100%',marginTop:-35,fontFamily:'Arial',display:'flex',justifyContent:'center',flexDirection:'row',}}>
      <Grid item xs= {12} md={4}>
        <Paper style={{ backgroundColor: 'white', height: '100%',marginLeft:10,paddingLeft:10,paddingRight:10,marginBottom:10}}>
          <Box>
          <TextField id="standard-basic" label="Item-Code" variant="standard" fullWidth onKeyPress={handleKeyPress} inputRef={codeRef} autoComplete="off"/>
          </Box>
        <Box xs ={2} width={'100%'}>
            {/* bill */}
            <div style={styles.billTable}>
              <table width={'100%'}>
                <tr style={{backgroundColor : 'white',fontSize:18}} >
                  <th align='left'></th>
                  <th align='left'>Item</th>
                  <th align='center'>Qty</th>
                  <th align='center'>Price</th>
                  <th style={{textAlign : 'center'}}>Total</th>
                </tr>
                <tr style={{backgroundColor : 'White',fontSize:18}}>
                  <td align='center' colSpan={6}><hr></hr></td>
                </tr>
                {items.slice().reverse().map((e,i) =>(
                  <>
                  <tr style={{fontSize:18,fontFamily : 'consolas'}}>
                    <td align='left'>{i+1}).</td>
                    <td align='left' width={100} colSpan={5}>{e.name}</td>
                  </tr>


                  <tr style={{backgroundColor : 'white',borderBottom : '2px solid black'}}>
                    <td></td>
                    <td></td>
                    <td align='center' style={{fontWeight : 'bold'}}>{ e.qty > 1 ? <button onClick={() =>{changeQtyMinus(e)}} style={{borderRadius : 50,borderWidth : 0,height:30,width:30,fontWeight : 'bold',marginRight:10,backgroundColor: '#000000',color: 'white',fontSize :18}}>-</button>:<><button style={{borderRadius : 50,borderWidth : 0,height:30,width:30,fontWeight : 'bold',marginRight:10,backgroundColor: '#000000',color: 'white',fontSize :18}}>-</button></>}{e.qty}<button onClick={() =>{changeQtyPlus(e)}} style={{borderRadius : 50,borderWidth : 0,height:30,width:30,fontWeight : 'bold',marginLeft:10,fontSize :18,backgroundColor: '#000000',color: 'white'}}>+</button></td>
                    <td align='center' style={{fontWeight : 'bold'}}>{e.Price}</td>
                    <td align='Right' onClick={handleOpenDisc} style={{fontWeight : 'bold'}}>{e.Price*e.qty}.00</td>
                    <td>
                    <img src ={require('../../Assests/delete.png')} alt = 'billImage' style={styles.deleteBtn} width={20} onClick={() => {setItems(items.filter(item => item.name !== e.name)); setTotal(total-parseFloat(e.Price)); codeRef.current.focus();}}></img>
                      </td>

                  </tr>
                  </>
                ))}
              </table> 
            </div>
        <div style={{fontSize: 40,color: 'Black',fontWeight : 'bold',marginTop:10}}>
          <div>
            Total  Rs.<label style={{ color: 'gray' }}>  {total}.00</label><br></br>
          </div>
          {/* <div style={{ fontSize: 30, color: 'gray' }}>
            Discount Rs.<label style={{ color: 'gray' }}>  {discount}.00</label><br></br>
          </div> */}
         </div>
         <div >
          {total >0 ? <>
            <button style={{width : '100%',fontSize :30,height :65,marginTop:10,fontWeight : 'bold',backgroundColor : '#00B912',borderRadius : 5,borderWidth : 0,color:"white",display:'flex',justifyContent:'center',alignItems:'center'}} onClick={handleOpen}>
                Pay Rs.{total-discount}.00
              <img src ={require('../../Assests/cash.png')} alt = 'billImage'  style = {{width : 45,marginLeft : 10}}></img>
            </button></>:<>
            <button style={{width : '100%',fontSize :30,height :65,marginTop:10,fontWeight : 'bold',backgroundColor : 'gray',borderRadius : 5,borderWidth : 0,color:"white"}}>
                Pay Rs.{total-discount}.00
              <img src ={require('../../Assests/cash.png')} alt = 'billImage'  style = {{width : 45,marginLeft : 10,marginTop:-10}}></img>
            </button></>}
        </div>
      </Box>
        </Paper>
      </Grid>
      <Grid item xs = {12} md={8}>
        <Paper style={{ backgroundColor: 'white', height: '100%',marginRight:10 }}>
          <TableTabs bill = {addToBill}/>
        </Paper>
      </Grid>
    </Grid>

    <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Payment Methods
            </Typography>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="paymentMethod"
                onChange={(e) => { setPaymentMethos(e.target.value) }}
                value={paymentMethod}
              >
                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                <FormControlLabel value="Card" control={<Radio />} label="Card" />
              </RadioGroup>
            </div>
            {paymentMethod === 'Cash' ? (
              <div style={{ margin: 10, display: 'flex', height: '35vh', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'left' }}>
                <Typography id="modal-modal-description" style={{ fontSize: 35, color: 'green' }}>
                  Total : Rs.{total - discount}.00
                </Typography>
                <Typography id="modal-modal-description" style={{ fontSize: 25, color: cash - (total - discount) < 0 ? 'red' : 'blue' }}>
                  {(cash - (total - discount)) < 0 ? (<>Balance : -- --</>) : (<>
                    Balance : Rs. {cash - (total - discount)}.00<br></br>
                  </>
                  )}
                  <hr></hr>
                </Typography>

              <TextField id="standard-basic" label="Amount in Rs."  onChange={(e) =>{setCash(e.target.value)}} xs = 'large' type='number' autoComplete="off"/>
              {/* <input type='text' onChange={(e) =>{setCash(e.target.value)}} value={cash} style={{fontSize:25,padding:5}} placeholder= 'Enter Amount in Rs.'/> */}
              {/* <hr></hr> */}
              <div style={{fontSize : 25,alignItems : 'center'}}>

              </div>

                {(cash - (total - discount)) < 0 ? (
                  <button style={{ width: '100%', height: 80, fontSize: 30, marginTop: 15, fontWeight: 'bold', backgroundColor: '#A7A7A7', borderRadius: 5, borderWidth: 0, alignItems: 'center', color: "black" }}>
                    PRINT
                    <IconButton>
                      <PrintIcon style={{ fontSize: 40, color: 'white' }} />
                    </IconButton>
                    {/* <img src ={require('../../Images/print.png')} alt = 'billImage'  style = {{width : 40,marginBottom : -7,marginLeft : 20}}></img> */}
                  </button>
                ) : (
                  <>

                    <button style={{ width: '100%', height: 80, fontSize: 30, marginTop: 15, fontWeight: 'bold', backgroundColor: '#0029FF', borderRadius: 5, borderWidth: 0, alignItems: 'center', color: "white" }} onClick={() => { toPrint() }}>
                      PRINT
                      {/* <img src ={require('../../Images/print.png')} alt = 'billImage'  style = {{width : 40,marginBottom : -7,marginLeft : 20}}></img> */}
                      <IconButton>
                        <PrintIcon style={{ fontSize: 40, color: 'white' }} />
                      </IconButton>
                    </button>
                  </>
                )}
              </div>) : <div style={{ margin: 10, display: 'flex', height: '20vh', flexDirection: 'column', justifyContent: 'space-around' }}>
              <Typography id="modal-modal-description" style={{ fontSize: 35, color: 'green' }}>
                Total : Rs.{total - discount}.00
              </Typography>
              <button style={{ width: '100%', height: 80, fontSize: 30, marginRight: 5, fontWeight: 'bold', backgroundColor: '#0029FF', borderRadius: 5, borderWidth: 0, alignItems: 'center', color: "white" }} onClick={() => { toPrint() }}>
                PRINT
                {/* <img src ={require('../../Images/print.png')} alt = 'billImage'  style = {{width : 40,marginBottom : -7,marginLeft : 20}}></img> */}
                <IconButton>
                  <PrintIcon style={{ fontSize: 40, color: 'white' }} />
                </IconButton>
              </button>

            </div>}


          </Box>
        </Modal>
      </div>



      <div>
      <Modal
        open={openDisc}
        onClose={handleCloseDisc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={styles.style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Discount Amount :
          </Typography>
          <br></br>
          <br></br>

        <TextField id="standard-basic" label="Discount in Rs." variant="standard" onChange={(e) =>{setDiscount(e.target.value)}} type='number' autoComplete="off"/><br></br><br></br>

        <div style={ {fontSize : 20}}> 
            <br></br>
            <br></br>
          <Button variant="outlined" onClick = {() =>{addDiscount()}} style={{width : 300}}>Add Discount</Button>
        </div>

      </Box>
      </Modal>
      {/* Bill */}
      <div style={{display : 'none',position : 'relative',zIndex:1000,justifyContent : 'center',top : 0,jeft :0,width : '100%',height : '100%',alignItems : 'center'}}>
      <table border={0} width={'300'} style={{ fontFamily: 'Consolas',backgroundColor : 'white' }} id='BillingDoc' ref={printsRef}>
          <tr>
            <td colSpan={4} align='center'><img src={require('../../Assests/bm2.png')} alt='' width={200} style={{marginBottom:-30}}></img></td>
          </tr>
          {JSON.parse(localStorage.getItem("loggedDetails"))?.shop === "Warakapola" ? (

            <tr>
              <td colSpan={4} align='center' style={{ fontSize: 14, fontWeight: 'bold' }}><label style={{ fontSize: 20 }}>BLOOMMARK</label><br></br>Mainnoluwa,<br></br>
              Aguruwella Road,
              Warakapola.<br></br>Tel. (071) 317 1143 </td>
            </tr>
          ):
          (

          <tr>
            <td colSpan={4} align='center' style={{ fontSize: 14, fontWeight: 'bold' }}><label style={{ fontSize: 20 }}>BLOOMMARK</label><br></br>No 5 Avissawella Road, <br></br>
            Galigamuwa.<br></br>Tel. (071) 317 1143 </td>
          </tr>
          )}

          <tr>
            <td colSpan={4} align='right'> ---------------------------------</td>
          </tr>
          <tr colSpan={4} align='center'>
            <td colSpan={4} align='left'>Cashier : {metaData?.user}</td>
          </tr>
          <tr colSpan={4} align='center'>
            <td colSpan={4} align='left'>Date : {`${year}-${month}-${day} | Time: ${hours}:${minutes}`}</td>
          </tr>
          <tr colSpan={4} align='center'>
            <td colSpan={4} align='left'>Invoice no : {`${year}${month}${day}${hour}BM${invno}`}</td>
          </tr>
          <tr>
            <td colSpan={4} align='right'> ---------------------------------</td>
          </tr>
          {/* <tr>
              <td > </td>
              <td > Item</td>
              <td > Qty</td>
              <td > Price</td>
              <td align='center'> Total</td>
            </tr> */}
          {items?.map((e, i) => (
            <>
              <tr>
                <td align='right'>{i + 1}<>).</></td>
                <td colSpan={3}>{e.name}</td>
              </tr>
              <tr style={{ backgroundColor: 'white' }}>
                <td ></td>
                <td align='right'>{e.qty}</td>
                <td align='center' colSpan={1}>{e.Price}.00</td>
                <td align='right'>{e.qty * e.Price}.00</td>
              </tr>
              </>
            ))}
            <tr>
              <td colSpan={4} align='right'> --------------------------------- </td>
            </tr>
            <tr>
              <td colSpan={4} align='right'>Gross  Rs.{metaData?.Total}.00</td>
            </tr>
            <tr>
              <td colSpan={4} align='right'>Discount  Rs.({metaData?.Discount}.00)</td>
            </tr>
            <tr>
              <td colSpan={4} align='right'>--------------------------------- </td>
            </tr>
            <tr>
              <td colSpan={3} align='left' style={{fontWeight : 'bold'}}>Total</td>
              <td colSpan={1} align='right' style={{fontWeight : 'bold'}}>Rs.{metaData?.Total-metaData?.Discount}.00</td>
            </tr>
            <tr>
              <td colSpan={4} align='right'>==========</td>
            </tr>
            <tr>
              <td colSpan={3} align='left'>{metaData?.Method} </td>
                {metaData?.Method === 'Card' ? (<>
                  <td colSpan={1} align='right'>Rs.{metaData?.Amount-metaData?.Discount}.00</td>
                </>):
                  <td colSpan={1} align='right'>Rs.{metaData?.Amount}.00</td>
                }
            </tr>
            <tr>
              <td colSpan={3} align='left'>Balance  </td>
              {metaData?.Method === 'Card' ? (<>
                <td colSpan={1} align='right'>Rs.00.00</td>
              </>):
                <td colSpan={1} align='right'>Rs.{metaData?.Amount-(metaData?.Total-metaData?.Discount)}.00</td>
              }
            </tr>
            <tr>
              <td colSpan={4} align='center' style={{fontSize : 10}}><br></br></td>
            </tr>
            <tr>
              <td colSpan={4} align='center' style={{fontSize : 15}}>Thank you. Come Again!</td>
            </tr>
            <tr>
              <td colSpan={4} align='center'>---------------------------------</td>
            </tr>
            <tr>
              <td colSpan={4} align='center' style={{fontSize : 13,fontWeight:'bold'}}>Software by CC Software Solutions</td>
            </tr>
            <tr>
              <td colSpan={4} align='center' style={{fontSize : 13,fontWeight:'bold'}}>077 0832 340</td>
            </tr>
          </table>
  
  
      </div>
    </div>
    </>
  )
}

export default MainBilling



