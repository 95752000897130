import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function User() {
  const [AddUser, setAddUser] = useState([]);
  const [centredModal, setCentredModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State to track if editing or adding
  const [userIdToEdit, setUserIdToEdit] = useState(null); // State to store the user id being edited
  const [userName, setUserName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [permission, setPermission] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [shop, setShop] = useState('');

  ////console.log(AddUser)

  
  const navigate = useNavigate()

  
  useEffect(() => {
    if(!localStorage.getItem("user")){
        navigate("/Login")
      }
    
}, [])

  useEffect(() => {
    axios
      .get("https://bmserver.ccsoftwares.com/User")
      .then((res) => {
        setAddUser(res.data.AddUser);
      })
      .catch((err) => {
        console.error(err);
        alert("An error occurred while fetching users.");
      });
  }, [AddUser]);

  const handleOpenModal = (editUserId = null) => {
    if (editUserId) {
      // If editing an existing user, set the form fields with existing data
      const userToEdit = AddUser.find(user => user._id === editUserId);
      if (userToEdit) {
        setUserName(userToEdit.userName);
        setMobileNumber(userToEdit.mobileNumber);
        setPermission(userToEdit.permission);
        setEmail(userToEdit.email);
        // setPassword(userToEdit.password);
        setIsEditing(true);
        setUserIdToEdit(editUserId);
        setShop(userToEdit.shop)
      } else {
        alert("User not found for editing.");
      }
    } else {
      // If adding a new user, reset form fields
      setUserName('');
      setMobileNumber('');
      setPermission('');
      setEmail('');
      setPassword('');
      setShop('');
      setIsEditing(false);
      setUserIdToEdit(null);
    }
    setCentredModal(true);
  };

  const handleCloseModal = () => {
    setCentredModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      userName: userName,
      mobileNumber: mobileNumber,
      role: permission,
      email: email,
      password: password,
      shop : shop
    };

    if (isEditing) {
      //console.log(userData)
      //console.log(userIdToEdit)
      // If editing, send a PUT request to update user details
      axios.put(`https://bmserver.ccsoftwares.com/User/Update/${userIdToEdit}`, userData)
        .then((res) => {
          alert("User details updated!");
          handleCloseModal(); // Close modal after successful update
          // Update user details in state
          setAddUser(prevUsers => {
            return prevUsers.map(user => {
              if (user._id === userIdToEdit) {
                return { ...user, ...userData };
              }
              return user;
            });
          });
        })
        .catch((err) => {
          console.error(err);
          alert("An error occurred while updating user details.");
        });
    } else {
      // If adding a new user, send a POST request to add user
      axios.post("https://bmserver.ccsoftwares.com/User/AddUser", userData)
        .then((res) => {
          alert("New user added!");
          handleCloseModal(); // Close modal after successful submission
          // Add new user to state
          setAddUser(prevUsers => [...prevUsers, res.data.newUser]);
        })
        .catch((err) => {
          console.error(err);
          alert("An error occurred while adding the user.");
        });
    }
  };

  const deleteRecord = (userId) => {

    const result = window.confirm("Confirm Delete user ?");

    if (result) {

      
      
      axios
      .delete(`https://bmserver.ccsoftwares.com/User/Delete/${userId}`)
      .then((res) => {
        if (res.data.state === 'Deleted') {
          alert("User deleted!");
          // Remove deleted user from state
          setAddUser(prevUsers => prevUsers.filter(user => user._id !== userId));
        }
      })
      .catch((err) => {
        console.error(err);
        alert("An error occurred while deleting the user.");
      });
    } 
  };

  return (
    <>
      <style>
        {`
          @media (max-width: 600px) {
            div {
              height: auto;
            }
            h1 {
              font-size: 24px;
            }
            button {
              margin-left: 0;
              margin-top: 10px;
            }
          }

          body.modal-open {
            filter: blur(5px); /* Adjust blur amount as needed */
          }
        `}
      </style>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '25px', marginTop: '25px',justifyContent : 'space-between' }}>
          <h1>User Management</h1>
          <Button variant="outlined" onClick={() => handleOpenModal()} sx={{ color: 'black', borderColor: 'black', marginLeft: '35vw ' }}>
            Add New User
          </Button>
        </div>
        <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '80%' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><b>User Name</b></TableCell>
                <TableCell align="center"><b>Mobile Number</b></TableCell>
                <TableCell align="center"><b>permission Level</b></TableCell>
                <TableCell align="center"><b>Shop</b></TableCell>
                <TableCell align="center"><b>Email</b></TableCell>
                <TableCell align="center"><b>Action</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AddUser.map((user) => (
                <TableRow key={user._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {user.userName}
                  </TableCell>
                  <TableCell align="center">{user.mobileNumber}</TableCell>
                  <TableCell align="center">{user.role}</TableCell>
                  <TableCell align="center">{user.shop}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">
                    <IconButton sx={{ color: 'black' }} onClick={() => handleOpenModal(user._id)}>
                      <EditIcon fontSize='inherit' />
                    </IconButton>
                    <IconButton sx={{ color: 'red' }} onClick={() => deleteRecord(user._id)}>
                      <DeleteForeverIcon fontSize='inherit' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add/Edit Modal */}
        <MDBModal tabIndex='-1' open={centredModal} setOpen={setCentredModal}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>{isEditing ? 'Update User' : 'Add New User'}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={handleCloseModal}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control type="text" value={userName} onChange={(e) => setUserName(e.target.value)} required placeholder="Enter User Name" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicMobileNumber">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required placeholder="Enter Mobile Number" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicGender">
                    <Form.Label>Permission Level</Form.Label>
                    <Form.Select value={permission} onChange={(e) => setPermission(e.target.value)} required>
                      <option >Select</option>
                      <option value={'Admin'}>Admin Level Permission</option>
                      <option value={'User'}>User Level Permission</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicGender">
                    <Form.Label>Shop</Form.Label>
                    <Form.Select value={shop} onChange={(e) => setShop(e.target.value)} required>
                      <option >Select</option>
                      <option value={'Warakapola'}>Warakapola Shop</option>
                      <option value={'Galigamuwa'}>Galigamuwa Shop</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Enter Email" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required placeholder="Enter Password" />
                  </Form.Group>
                  <MDBBtn color='black' type='submit'>{isEditing ? 'Update' : 'Save'}</MDBBtn>
                </Form>
              </MDBModalBody>
              <MDBModalFooter></MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    </>
  );
}

export default User;
