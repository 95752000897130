import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, CardHeader, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, TextField, Tooltip, styled } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from "yup";
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DialogCmpt({ dialogOpen, setDialogOpen, updateID, setUpdateID }) {
    const inputRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState('lg');
    const [itemData, setitemData] = useState({
        itemCategories: '',
        itemCode: 0,
        itemName: '',
        costPrice: '',
        sellingPrice: ''
    })
    const [itemCategory, setItemCategory] = useState([]);
    const [itemCodeNew, setItemCodeNew] = useState(0);
    const [img, setImg] = useState();
    const [previewImg, setPreviewImg] = useState();
    const [isUpdate, setIsUpdate] = useState(false);
    const [itemCategoryName, setItemCategoryName] = useState('');
    const [isImageEnable, setIsImageEnable] = useState(false);
    const [itemID, setItemID] = useState();
    const [isImageUpload, setIsImageUpload] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            getAllItemCategory()
        }
    }, [dialogOpen])

    useEffect(() => {
        if (updateID) {
            setIsUpdate(true);
            axios.get(`https://bmserver.ccsoftwares.com/item/getItemDetailByID/${updateID}`).then((res => {
                if (res.data.item.length > 0) {
                    setitemData({
                        ...itemData,
                        itemCategories: parseInt(res.data.item[0].itemCategories),
                        itemName: res.data.item[0].itemName,
                        costPrice: res.data.item[0].costPrice,
                        sellingPrice: res.data.item[0].sellingPrice
                    })
                    setItemCodeNew(res.data.item[0].itemCodeNew);
                    setPreviewImg(res.data.item[0].image)
                    setItemID(res.data.item[0]._id);
                }
            }))
        }
    }, [updateID])

    useEffect(() => {
        if (itemData.itemCategories) {
            generateItemCode();
            getCategoryNameByCategoryID();
        }
    }, [itemData.itemCategories])


    async function getAllItemCategory() {
        axios.get('https://bmserver.ccsoftwares.com/category/').then((res => {
            if (res.data.data.length > 0) {
                setItemCategory(res.data.data)
            }
        }))
    }

    function generateItemCode() {
        if (!isUpdate) {
            const Name = itemCategory.find(item => item.Category_id === itemData.itemCategories);
            const catNmae = Name.name;
            const cOne = catNmae.toString().split("")[0].toUpperCase();
            const cTwo = catNmae.toString().split("")[1].toUpperCase();
            const currentDate = new Date();
            const year = currentDate.getFullYear().toString().slice(-2);
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const date = currentDate.getDate().toString().padStart(2, '0');
            const hour = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            const seconds = currentDate.getSeconds().toString().padStart(2, '0');
            const itemCode = `${cOne}${cTwo}${year}${month}${date}${hour}${minutes}${seconds}`;
            setItemCodeNew(itemCode);
        }
    }

    function getCategoryNameByCategoryID() {
        const Name = itemCategory.find(item => item.Category_id === itemData.itemCategories);
        const catNmae = Name.name;
        setItemCategoryName(catNmae);
    }

    function handleChangenNew(e) {
        const target = e.target;
        const value = target.value;
        setitemData({
            ...itemData,
            [e.target.name]: value
        })
    }

    function uploadItem() {
        if (isUpdate) {
            const formData = new FormData();
            formData.append("itemID", itemID)
            formData.append("itemName", itemData.itemName)
            formData.append("costPrice", itemData.costPrice)
            formData.append("sellingPrice", itemData.sellingPrice)
            formData.append("file", isImageUpload ? img : previewImg)

            axios.put('https://bmserver.ccsoftwares.com/item/updateItem', formData).then((res) => {
                if (res.data.status == 'Success') {
                    clearData();
                    setDialogOpen(false);
                    toast.success("Item Updated successfully!")
                }
            })
        } else {
            if (previewImg) {
                const formData = new FormData();
                formData.append("itemName", itemData.itemName)
                formData.append("itemCategories", itemData.itemCategories)
                formData.append("itemCategoryName", itemCategoryName)
                formData.append("itemCodeNew", itemCodeNew.toString())
                formData.append("costPrice", itemData.costPrice)
                formData.append("sellingPrice", itemData.sellingPrice)
                formData.append("file", img)

                axios.post('https://bmserver.ccsoftwares.com/item/createItem', formData).then((res) => {
                    if (res.data.ID) {
                        clearData();
                        setDialogOpen(false);
                        toast.success("Item Added successfully!")
                    }
                })
            } else {
                toast.error("Please Upload a Image!")
            }
        }
    }

    function clearData() {
        setitemData({
            ...itemData,
            itemCategories: '',
            itemName: '',
            costPrice: '',
            sellingPrice: ''
        })
        setItemCodeNew(0)
        setPreviewImg()
        if (inputRef.current) {
            inputRef.current.value = null; // Reset the input value of the image upload
        }
    }

    const [open, setOpen] = useState(dialogOpen);

    const handleClose = () => {
        setOpen(false);
        setDialogOpen(false);
        clearData();
        setUpdateID();
        setIsUpdate(false);
        setIsImageEnable(false);
    };

    const uploadImage = (e) => {
        setImg(e.target.files[0]);
        setPreviewImg(URL.createObjectURL(e.target.files[0]));
        setIsImageUpload(true);
    }

    function removeImage() {
        setImg();
        setPreviewImg();
        if (inputRef.current) {
            inputRef.current.value = null; // Reset the input value
        }
        setIsImageEnable(true);
    }


    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
            maxWidth={maxWidth}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {isUpdate ? 'Update Clothing Item' : 'Add Clothing Items'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon sx={{ color: 'black' }} />
            </IconButton>
            <DialogContent dividers>

                <Container maxWidth={false}>
                    <Formik
                        initialValues={{
                            itemName: itemData.itemName,
                            sellingPrice: itemData.sellingPrice,
                            costPrice: itemData.costPrice
                        }}

                        validationSchema={
                            Yup.object().shape({
                                itemName: Yup.string().required('Item Name is required'),
                                sellingPrice: Yup.string().required("Selling Price Is Required").matches(/^\d+$|^\d+\.\d{2}$ /, 'Allow Only Numbers '),
                                costPrice: Yup.string().required("Cost Price Is Required").matches(/^\d+$|^\d+\.\d{2}$ /, 'Allow Only Numbers '),
                            })
                        }

                        onSubmit={() => uploadItem()}
                        enableReinitialize
                    >

                        {({
                            errors,
                            handleBlur,
                            handleSubmit,
                            touched
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mt={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Item Category"
                                                id="outlined-size-small"
                                                size="small"
                                                name='itemCategories'
                                                value={itemData.itemCategories}
                                                onChange={(e) => handleChangenNew(e)}
                                                color='success'
                                                inputProps={{
                                                    readOnly: isUpdate
                                                }}
                                            >
                                                {itemCategory.map((row) => (
                                                    <MenuItem key={row.Category_id} value={row.Category_id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.itemCode && errors.itemCode)}
                                                helperText={touched.itemCode && errors.itemCode}
                                                label="Item Code *"
                                                name='itemCode'
                                                value={itemCodeNew}
                                                id='itemCode'
                                                onChange={(e) => handleChangenNew(e)}
                                                variant="outlined"
                                                size="small"
                                                color='success'
                                                InputProps={{
                                                    readOnly: 'true'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.itemName && errors.itemName)}
                                                helperText={touched.itemName && errors.itemName}
                                                label="Item Name *"
                                                name='itemName'
                                                value={itemData.itemName}
                                                id='itemName'
                                                onChange={(e) => handleChangenNew(e)}
                                                variant="outlined"
                                                size="small"
                                                color='success'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.costPrice && errors.costPrice)}
                                                helperText={touched.costPrice && errors.costPrice}
                                                label="Cost Price"
                                                name='costPrice'
                                                value={itemData.costPrice}
                                                id='costPrice'
                                                onChange={(e) => handleChangenNew(e)}
                                                variant="outlined"
                                                size="small"
                                                color='success'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.sellingPrice && errors.sellingPrice)}
                                                helperText={touched.sellingPrice && errors.sellingPrice}
                                                label="Selling Price *"
                                                name='sellingPrice'
                                                value={itemData.sellingPrice}
                                                id='sellingPrice'
                                                onChange={(e) => handleChangenNew(e)}
                                                variant="outlined"
                                                size="small"
                                                color='success'
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                            <input
                                                ref={inputRef}
                                                type='file'
                                                accept='image/*'
                                                onChange={(e) => uploadImage(e)}
                                                className='inputImage'
                                                disabled={previewImg}
                                                style={{
                                                    width: '400px',
                                                    padding: '8px',
                                                    border: '1px solid #ced4da',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#fff',
                                                    color: '#495057',
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    boxSizing: 'border-box',
                                                    height: '50px'
                                                }}
                                            />
                                        </Grid>
                                        {(previewImg && !isUpdate) ?
                                            <Grid item md={3} xs={12}>
                                                <Card >
                                                    <center>
                                                        <img
                                                            src={previewImg}
                                                            width='200vw'
                                                            height='200vh'
                                                        />
                                                        <IconButton>
                                                            <CancelIcon
                                                                sx={{ color: 'red' }}
                                                                onClick={removeImage}
                                                            />
                                                        </IconButton>
                                                    </center>
                                                </Card>
                                            </Grid>
                                            : null}
                                        {(previewImg && isUpdate) ?
                                            <Grid item md={3} xs={12}>
                                                <Card >
                                                    {isImageEnable ?
                                                        <center>
                                                            <img
                                                                src={previewImg}
                                                                width='200vw'
                                                                height='200vh'
                                                            />
                                                            <IconButton>
                                                                <CancelIcon
                                                                    sx={{ color: 'red' }}
                                                                    onClick={removeImage}
                                                                />
                                                            </IconButton>
                                                        </center>
                                                        :
                                                        <center>
                                                            <img
                                                                src={`https://bmserver.ccsoftwares.com/assets/images/${previewImg}`}
                                                                width='200vw'
                                                                height='200vh'
                                                            />
                                                            <IconButton>
                                                                <CancelIcon
                                                                    sx={{ color: 'red' }}
                                                                    onClick={removeImage}
                                                                />
                                                            </IconButton>
                                                        </center>
                                                    }
                                                </Card>
                                            </Grid>
                                            : null}
                                        <Grid item xs={12} md={12}>
                                            <Box display='flex' justifyContent='flex-end' alignItems='center' >
                                                {!isUpdate ?
                                                    <Button
                                                        variant="contained"
                                                        type='submit'
                                                        sx={{ width: '100px' }}
                                                        onClick={clearData}
                                                    >
                                                        clear
                                                    </Button> : null}
                                                &nbsp;
                                                <Button
                                                    variant="contained"
                                                    type='submit'
                                                    sx={{ width: '100px' }}
                                                // disabled={!previewImg}
                                                >
                                                    {!isUpdate ? 'Upload' : 'Update'}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </DialogContent>
        </Dialog>
    );
}