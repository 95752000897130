import React, { useEffect, useState } from 'react'
import {
    MenuItem,
    Tooltip,
    styled,
    Box,
    Button,
    Card,
    CardHeader,
    Container,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Dialog,
    DialogContent,
    DialogActions,
    Typography
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from "yup";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DialogCmpt from '../../Components/DialogCmpt';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast, useToastContainer } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

      
  const navigate = useNavigate()
    useEffect(() => {
        if(!localStorage.getItem("user")){
            navigate("/Login")
          }
        
    }, [])

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function ItemCatogory() {
    const [pageTitle, setPageTitle] = useState('Clothing Item');
    const [formData, setFormData] = useState({
        categoryName: 0
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [itemCategory, setItemCategory] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [imagePrew, setImagePrew] = useState();
    const [imageOpen, setImageOpen] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteID, setDeleteID] = useState();
    const [updateID, setUpdateID] = useState();
    const [searchDevice, setSearchDevice] = useState("");

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allItems.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getAllItemCategory()
    }, [])

    useEffect(() => {
        searchData();
    }, [dialogOpen])

    useEffect(() => {
        searchData();
    }, [formData.categoryName])

    function handleChange(e) {
        const target = e.target;
        const value = target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        })
    }

    function handleClick() {
        setDialogOpen(true);
    }

    async function getAllItemCategory() {
        axios.get('https://bmserver.ccsoftwares.com/category/').then((res => {
            if (res.data.data.length > 0) {
                setItemCategory(res.data.data)
            }
        }))
    }

    async function searchData() {
        if (formData.categoryName == 0) {
            axios.get('https://bmserver.ccsoftwares.com/item/').then((res => {
                if (res.data.length > 0) {
                    setAllItems(res.data)
                } else {
                    setAllItems([]);
                    toast.error("No records Found")
                }
            }))
        } else {
            axios.get(`https://bmserver.ccsoftwares.com/item/getItem/${formData.categoryName}`).then((res => {
                if (res.data.item.length > 0) {
                    setAllItems(res.data.item)
                } else {
                    setAllItems([]);
                    toast.error("No records Found")
                }
            }))
        }
    }

    function DeleteRecord(deleteID) {
        setDeleteID(deleteID)
        setDeleteDialog(true);
    }

    function imagePreview(image) {
        setImagePrew(image);
        setImageOpen(true)
    }

    function removeImage() {
        setImageOpen(false);
    }

    function handleCancelDelete() {
        setDeleteDialog(false);
    }

    function handleConfirmDelete() {
        axios.delete(`https://bmserver.ccsoftwares.com/item/deleteItem/${deleteID}`).then((res) => {
            if (res.data.status === "Success") {
                searchData();
                setDeleteDialog(false);
            }
        })
    }

    function UpdateRowData(id) {
        setUpdateID(id)
        setDialogOpen(true);
    }

    function cardTitle(titleName) {
        return (
            <Grid container spacing={1}>
                <Grid item md={11} xs={11}>
                    {titleName}
                </Grid>
                <Grid item md={1} xs={1}>
                    <Box display='flex' justifyContent='flex-end' alignItems='center' >
                        <Tooltip title="Add Cloths">
                            <IconButton>
                                <AddCircleOutlineIcon onClick={() => handleClick()} sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Container maxWidth={false}>
                <Formik
                    initialValues={{
                        categoryID: formData.categoryID,
                        categoryName: formData.categoryName
                    }}
                    validationSchema={
                        Yup.object().shape({
                            // categoryName: Yup.required('Factory is required'),
                        })
                    }
                    onSubmit={() => searchData()}
                    enableReinitialize
                >
                    {({
                        errors,
                        handleBlur,
                        handleSubmit,
                        touched
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Box mt={0}>
                                <Card sx={{ padding: '15px' }}>
                                    <CardHeader
                                        title={cardTitle(pageTitle)}
                                    />
                                    <Divider />
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Item Category"
                                                id="outlined-size-small"
                                                size="small"
                                                name='categoryName'
                                                value={formData.categoryName}
                                                onChange={(e) => handleChange(e)}
                                                color='success'
                                            >
                                                <MenuItem value={0}>All Category</MenuItem>
                                                {itemCategory.map((row) => (
                                                    <MenuItem key={row.Category_id} value={row.Category_id}>
                                                        {row.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    {allItems.length > 0 ?
                                        <TableContainer>
                                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                                <TextField
                                                    id="input-with-sx"
                                                    variant="standard"
                                                    value={searchDevice}
                                                    onChange={(e) => { setSearchDevice(e.target.value) }}
                                                    color='success'
                                                />
                                            </Box>
                                            <br />
                                            <Table sx={{ minWidth: 500 }} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align='center'>ItemCode</TableCell>
                                                        <TableCell align='center'>Item Name</TableCell>
                                                        <TableCell align='center'>Item Category</TableCell>
                                                        <TableCell align='center'>CostPrice</TableCell>
                                                        <TableCell align='center'>Selling Price</TableCell>
                                                        <TableCell align='center'>Image</TableCell>
                                                        <TableCell align='center'>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(rowsPerPage > 0
                                                        ? allItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : allItems
                                                    ).filter((element) => {
                                                        if (searchDevice === "") {
                                                            return element;
                                                        } else if ((element.itemCodeNew.toLowerCase()).includes(searchDevice.toLowerCase())) {
                                                            return element;
                                                        } else if ((element.itemName.toLowerCase()).includes(searchDevice.toLowerCase())) {
                                                            return element;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow key={row._id}>
                                                            <TableCell align='center'>{row.itemCodeNew}</TableCell>
                                                            <TableCell align='center'>{row.itemName}</TableCell>
                                                            <TableCell align='center'>{row.itemCategoryName}</TableCell>
                                                            <TableCell align='center'>{row.costPrice}</TableCell>
                                                            <TableCell align='center'>{row.sellingPrice}</TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton
                                                                    onClick={() => imagePreview(row.image)}
                                                                >
                                                                    <VisibilityIcon sx={{ color: 'black' }} />
                                                                </IconButton>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton>
                                                                    <CreateIcon sx={{ color: 'black' }} onClick={() => UpdateRowData(row._id)} />
                                                                </IconButton>
                                                                &nbsp;
                                                                <IconButton>
                                                                    <DeleteIcon sx={{ color: 'red' }} onClick={() => DeleteRecord(row._id)} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={7} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 25, 35, { label: 'All', value: -1 }]}
                                                            colSpan={7}
                                                            count={allItems.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            slotProps={{
                                                                select: {
                                                                    inputProps: {
                                                                        'aria-label': 'rows per page',
                                                                    },
                                                                    native: true,
                                                                },
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                        : null}

                                    {/* image preview dialog */}
                                    <Dialog open={imageOpen}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                paddingTop: "1vh",
                                                paddingRight: "1vw",
                                            }}
                                        >
                                            <IconButton>
                                                <CancelIcon
                                                    sx={{ color: "red", fontSize: "2vw" }}
                                                    onClick={removeImage}
                                                />
                                            </IconButton>
                                        </Box>
                                        <DialogContent>
                                            <Card
                                                style={{
                                                    height: 400,
                                                    width: 300,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={`https://bmserver.ccsoftwares.com/assets/images/${imagePrew}`}
                                                    height="100%"
                                                    width="auto"
                                                    alt='imag'
                                                />
                                            </Card>
                                        </DialogContent>
                                    </Dialog>

                                    {/* delete dialog box */}
                                    <Dialog
                                        open={deleteDialog}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <DialogContent>
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Do you want to delete this record?
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <br />
                                            </Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                sx={{ color: "black", fontWeight: "bold" }}
                                                autoFocus
                                                onClick={handleCancelDelete}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                sx={{ color: "black", fontWeight: "bold" }}
                                                onClick={handleConfirmDelete}
                                                autoFocus
                                            >
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Card>
                            </Box>
                        </form>
                    )
                    }
                </Formik>
            </Container>

            {/* dialog box */}
            <DialogCmpt
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                updateID={updateID}
                setUpdateID={setUpdateID}
            />
        </>

    )
}

export default ItemCatogory