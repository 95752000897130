import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Items from './Items';
import { TextField, useStepContext } from '@mui/material';
import axios from 'axios'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function TableTabs(props) {

  const [value, setValue] = React.useState(0);
  const [change, setChange] = useState(0)
  const [categories, setCategories] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


      const [allItems, setAllItems] = useState([])

      const [triggerItem,setTriggerItem] = useState('')
  useEffect(() => {

    axios.get("https://bmserver.ccsoftwares.com/category").then(res => {
      setCategories(res.data.data)
    }).catch(e => {
      //console.log(e)
    })

    axios.get("https://bmserver.ccsoftwares.com/item").then(res => {
      setAllItems(res.data)
    }).catch(e => {
      //console.log(e)
    })


  }, [triggerItem])







  const [search, setSearch] = useState('')

  return (
    <Box sx={{ width: 'auto' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable">
          {categories.map((e, i) => (
            <Tab label={e.name} {...a11yProps(e.category_id)} style={{ fontWeight: '900' }} />
          ))}

        </Tabs>
      </Box>

      {categories.map((e, i) => (
        <CustomTabPanel value={value} index={i}>

          <TextField id="outlined-basic" label="Search..." variant="outlined" onChange={(e) => {
            setSearch(e.target.value);
          }} value={search} style={{ width: '100%' }} placeholder='Search...' />

          <hr></hr>
          <div style={{ overflow: 'scroll', overflowX: 'hidden', height: '89%' }}>

            <Items items={allItems.filter((element) => {

              if (search === "" && parseInt(element.itemCategories) === (e.Category_id)) {
                return element

              }
              else if ((element.itemName.toLowerCase()).includes(search.toLowerCase()) && element.itemCategories === (e.Category_id)) {
                return element
              }
              else if ((element.itemName.toLowerCase()).includes(search.toLowerCase()) && element.isfavourite === 1 && e.Category_id === 1000 && e.name === 'Favourite') {
                return element
              }

            })} addToBill={props.bill} changeFunction={setAllItems} focus={props.focus} trig = {(e) => {setTriggerItem(e)}}/>

          </div>
        </CustomTabPanel>
      ))}

    </Box>
  );
}